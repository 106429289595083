import React, {useEffect, useState} from 'react';
import {FiCalendar, FiChevronRight, FiHome} from "react-icons/fi";
import {Link} from "react-router-dom";
import {useTranslation} from "react-i18next";
import {CustomDropdownFilterObject} from "../ressources/CustomComponents";
import {
  getAllPublishers,
  getCampaignsByPublisher,
  getDailyCampaignRevenue,
  getMonthlyCampaignRevenue
} from "../services/campaignServices";
import DatePicker from "react-datepicker";
import moment from "moment";
import cx from "classnames";
import {FaFileExport} from "react-icons/fa";
import * as XLSX from "xlsx";
import DataTable from "react-data-table-component";

function CampaignStatistics(props) {

  const {t} = useTranslation();

  const [isPublisherOpen, setIsPublisherOpen] = useState(false);
  const [publisherList, setPublisherList] = useState([]);
  const [publisher, setPublisher] = useState({});

  const [isCampaignOpen, setIsCampaignOpen] = useState(false);
  const [campaignList, setCampaignList] = useState([]);
  const [campaign, setCampaign] = useState({});

  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);

  const [dataType, setDataType] = useState("daily");
  const [loading, setLoading] = useState(false);

  const [campaignsData, setCampaignData] = useState([]);

  const selectPublisher = (val) => {
    setPublisher(val);
    setCampaign({});
    setIsPublisherOpen(false);
  }

  const selectCampaign = (val) => {
    setCampaign(val);
    setIsCampaignOpen(false);
  }

  function exportToExcel(tableData, fileName) {
    const ws = XLSX.utils.json_to_sheet(tableData);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Sheet1");
    XLSX.writeFile(wb, `${fileName}.xlsx`);
  }

  const fetchPublishers = async () => {
    await getAllPublishers()
      .then((res)=> setPublisherList(res.results) )
      .catch((err)=>console.log(err));
  }

  const fetchCampaigns = async () => {
    await getCampaignsByPublisher(publisher.publisherId)
      .then((res)=> setCampaignList(res.results) )
      .catch((err)=>console.log(err));
  }

  const fetchCampaignsData = async () => {
    setLoading(true);
    if(dataType === "daily"){
      await getDailyCampaignRevenue(campaign.campaignId, moment(startDate).format('YYYY-MM-DD'), moment(endDate).format('YYYY-MM-DD'))
        .then((res)=> setCampaignData(res.results) )
        .catch((err)=>console.log(err))
        .finally(()=> setLoading(false) );
    }else{
      await getMonthlyCampaignRevenue(campaign.campaignId, moment(startDate).format('YYYY-MM-DD'), moment(endDate).format('YYYY-MM-DD'))
        .then((res)=> setCampaignData(res.results) )
        .catch((err)=>console.log(err))
        .finally(()=> setLoading(false) );
    }
  }

  useEffect(()=>{
    fetchPublishers();
  }, []);

  useEffect(()=>{
    if(Object.keys(publisher).length > 0){
      fetchCampaigns();
    }
  }, [publisher]);

  useEffect(()=>{
    if(Object.keys(campaign).length > 0 && startDate && endDate){
      fetchCampaignsData();
    }
  }, [campaign, startDate, endDate, dataType]);

  const columns = [
    {
      name: dataType === "daily" ? 'Date' : 'Month',
      selector: row => dataType === "daily" ? moment(row.dated).format("DD-MM-YYYY") : row.monthName,
      sortable: true,
    },
    {
      name: 'Operator',
      selector: row => row.operatorName,
      sortable: true,
    },
    {
      name: 'Service',
      selector: row => row.serviceName,
      sortable: true,
    },
    {
      name: 'New Subscription',
      selector: row => row.newSubscription,
      sortable: true,
      right: true,
    },
    {
      name: 'Unsubscription',
      selector: row => row.unSubscription,
      sortable: true,
      right: true,
    },
    {
      name: 'Churn Count',
      selector: row => row.churnCount,
      sortable: true,
      right: true,
    },
    {
      name: 'Total Cost',
      selector: row => row.totalCost,
      sortable: true,
      right: true,
    },
    {
      name: 'Total Cost (USD)',
      selector: row => row.totalCostUSD,
      sortable: true,
      right: true,
    },
    {
      name: 'Total Revenue',
      selector: row => row.totalRevenue,
      sortable: true,
      right: true,
    },
    {
      name: 'Total Revenue (USD)',
      selector: row => row.totalRevenueUSD,
      sortable: true,
      right: true,
    },
    {
      name: 'Daily Revenue',
      selector: row => row.dailyRevenue,
      sortable: true,
      right: true,
    },
  ];

  const totalNewSubscription = campaignsData.reduce((acc, item) => acc + item.newSubscription, 0);
  const totalUnSubscription = campaignsData.reduce((acc, item) => acc + item.unSubscription, 0);
  const totalChurnCount = campaignsData.reduce((acc, item) => acc + item.churnCount, 0);
  const totalCost = campaignsData.reduce((acc, item) => acc + item.totalCost, 0);
  const totalCostUSD = campaignsData.reduce((acc, item) => acc + item.totalCostUSD, 0);
  const totalRevenue = campaignsData.reduce((acc, item) => acc + item.totalRevenue, 0);
  const totalRevenueUSD = campaignsData.reduce((acc, item) => acc + item.totalRevenueUSD, 0);
  const totalDailyRevenue = campaignsData.reduce((acc, item) => acc + item.dailyRevenue, 0);

  return (
    <div>
      <div>
        <div className="text-gray-500 flex items-center gap-2 mt-2 mb-6">
          <span className="text-xs font-semibold flex items-center">
            <FiHome size={12}/> <FiChevronRight size={12}/>
          </span>
          <h4 className="text-xs font-semibold text-gray-500">{t("CAMPAIGN_STATISTICS.TITLE")}</h4>
        </div>
        <div className="flex items-center justify-between mb-6">
          {/*<div className="w-1/4 text-start">*/}
          {/*  <h1 className="text-xl font-bold">{t("CAMPAIGN_STATISTICS.TITLE")}</h1>*/}
          {/*</div>*/}
          <div className="grid grid-cols-2 items-center gap-4 w-full">
            <div className="w-full">
              <CustomDropdownFilterObject
                label="Publishers"
                isOpen={isPublisherOpen}
                setIsOpen={setIsPublisherOpen}
                itemList={publisherList}
                selectedItem={publisher}
                itemKey="publisherName"
                idKey="publisherId"
                selectItem={selectPublisher}
              />
            </div>
            <div className="w-full">
              <CustomDropdownFilterObject
                label="Campaign"
                isOpen={isCampaignOpen}
                setIsOpen={setIsCampaignOpen}
                itemList={campaignList}
                selectedItem={campaign}
                itemKey="description"
                idKey="campaignId"
                selectItem={selectCampaign}
                disabled={Object.keys(publisher).length === 0}
              />
            </div>
            <div className="border rounded-lg px-2 py-1.5 text-sm flex items-center gap-2 bg-white w-full">
              <FiCalendar className="text-gray-400"/>
              <DatePicker
                selected={startDate}
                onChange={(e) => setStartDate(e)}
                type="text"
                dateFormat="dd/MM/yyyy"
                placeholderText='Date'
                isClearable
                disabled={Object.keys(campaign).length === 0}
                className="w-full"
                wrapperClassName="w-full"
              />
            </div>
            <div className="border rounded-lg px-2 py-1.5 text-sm flex items-center gap-2 bg-white w-full">
              <FiCalendar className="text-gray-400"/>
              <DatePicker
                selected={endDate}
                onChange={(e) => setEndDate(e)}
                type="text"
                dateFormat="dd/MM/yyyy"
                placeholderText='Date'
                isClearable
                disabled={Object.keys(campaign).length === 0}
                className="w-full"
                wrapperClassName="w-full"
              />
            </div>
            <div className="flex items-center gap-3 border rounded-lg p-2 bg-white">
              <div className="flex items-center gap-2">
                <input
                  type="radio"
                  checked={dataType === "daily"}
                  onChange={(e) => setDataType("daily")}
                />
                <label className="text-sm font-semibold text-gray-600">Daily</label>
              </div>
              <div className="flex items-center gap-2">
                <input
                  type="radio"
                  checked={dataType === "monthly"}
                  onChange={(e) => setDataType("monthly")}
                />
                <label className="text-sm font-semibold text-gray-600">Monthly</label>
              </div>
            </div>
            <button
              onClick={() => exportToExcel(campaignsData, `revenue-from-${moment(startDate).format('MM/DD/YYYY')}-to-${moment(endDate).format('MM/DD/YYYY')}`)}
              disabled={campaignsData.length <= 0}
              className={cx("bg-azure px-4 py-2 flex items-center gap-3 text-sm font-semibold rounded-lg text-white hover:opacity-60 hover:shadow", {
                'bg-azure': campaignsData.length > 0,
                'bg-gray-400': campaignsData.length <= 0,
              })}
            >
              <FaFileExport size={18}/>
              <span>Export file</span>
            </button>
          </div>
        </div>
      </div>
      {
        loading
          ?
          <div className='bg-white p-0 rounded-lg mt-4 shadow overflow-x-auto'>
            Loading...
          </div>
          :
          <div className='bg-white p-0 rounded-lg mt-4 shadow overflow-x-auto relative z-0 min-h-[60vh]'>
            <DataTable
              columns={columns}
              data={campaignsData}
              customStyles={{
                headCells: {
                  style: {
                    backgroundColor: '#f3f4f6',
                    fontWeight: 'bold',
                    textTransform: 'uppercase',
                    fontSize: '12px',
                  },
                },
                cells: {
                  style: {
                    padding: '16px',
                    textAlign: 'left',
                  },
                },
              }}
              highlightOnHover
              pagination
            />
          </div>
      }

      { campaignsData?.length > 0 &&
        <div className="bg-white p-2 rounded-lg shadow mt-4" >
          <table style={{width: '100%', borderCollapse: 'collapse'}}>
            <thead>
            <tr>
              <th></th>
              <th></th>
              <th></th>
              <th className="text-center text-sm text-gray-600 p-2">New Subscription</th>
              <th className="text-center text-sm text-gray-600 p-2">Unsubscription</th>
              <th className="text-center text-sm text-gray-600 p-2">Churn Count</th>
              <th className="text-center text-sm text-gray-600 p-2">Total Cost</th>
              <th className="text-center text-sm text-gray-600 p-2">Total Cost (USD)</th>
              <th className="text-center text-sm text-gray-600 p-2">Total Revenue</th>
              <th className="text-center text-sm text-gray-600 p-2">Total Revenue (USD)</th>
              <th className="text-center text-sm text-gray-600 p-2">Daily Revenue</th>
            </tr>
            </thead>
            <tbody>
            <tr>
              <td className="text-sm font-semibold">Total</td>
              <td></td>
              <td></td>
              <td className="text-center text-sm">{totalNewSubscription}</td>
              <td className="text-center text-sm">{totalUnSubscription}</td>
              <td className="text-center text-sm">{totalChurnCount}</td>
              <td className="text-center text-sm">{totalCost.toFixed(2)}</td>
              <td className="text-center text-sm">{totalCostUSD.toFixed(2)}</td>
              <td className="text-center text-sm">{totalRevenue.toFixed(2)}</td>
              <td className="text-center text-sm">{totalRevenueUSD.toFixed(2)}</td>
              <td className="text-center text-sm">{totalDailyRevenue.toFixed(2)}</td>
            </tr>
            </tbody>
          </table>
        </div>
      }
    </div>
  );
}

export default CampaignStatistics;

